import Vue from "vue";
import { init } from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import SentryUtil from "@/utils/sentryUtil";

if (
  !process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES ||
  process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES === "false"
) {
  const options = {
    dsn: process.env.VUE_APP_SENTRY_DSN,
    debug: false,
    autoSessionTracking: true,
    release: "VMC@" + process.env.VUE_APP_VERSION,
    integrations: [
      new Integrations.BrowserTracing({
        // eslint-disable-next-line
        // @ts-ignore
        Vue,
        logErrors: false,
        attachProps: true,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  };
  try {
    init(options);

    type VueType = typeof Vue;
    const sentry = {
      install(Vue: VueType) {
        Vue.prototype.$sentry = SentryUtil;
      },
    };
    Vue.use(sentry);
  } catch (e) {
    console.error("Error on sentry startup");
    console.log(e);
  }
}
