import Vue from "vue";
import Notifications from "vue-notification";
import NotificationService from "@/services/notificationService";

Vue.use(Notifications);

type VueType = typeof Vue;
const notification = {
  install(Vue: VueType) {
    Vue.prototype.$notification = NotificationService;
  },
};

Vue.use(notification);
