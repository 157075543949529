import Vue from "vue";
import { DateTime, Duration } from "luxon";
import { bytesToSize } from "@/utils";

import logger from "@/services/loggerService";

function dateParse(value: number, applyTimezone = false): DateTime {
  if (applyTimezone) {
    //TODO FIX ME
    logger.log("missing applyTimezone");
  }
  return DateTime.fromMillis(value);
}

Vue.filter("formatDateTime", (value: number) => {
  const date = dateParse(value);
  return date.toFormat("dd/LL/yyyy") + " - " + date.toFormat("HH:mm");
});

Vue.filter("formatDate", (value: number) => {
  const date = dateParse(value);
  return date.toFormat("dd/LL/yyyy");
});

Vue.filter("duration", (value: number) => {
  return Duration.fromMillis(value).toFormat("dd:hh:mm:ss");
});

Vue.filter("bytesToSize", (bytes: number) => {
  return bytesToSize(bytes);
});

Vue.filter("trend", (value: number) => {
  return value + " %";
});

Vue.filter("formatTime", (value: number, applyTimezone = false) => {
  const date = dateParse(value, applyTimezone);
  return date.toFormat("HH:mm");
});

//new filters
Vue.filter("date2string", (date: Date) => {
  if (!date) return "";
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  //TODO get locale string from user
  return date.toLocaleDateString(undefined, options);
});
Vue.filter("dateTime2string", (date: Date) => {
  if (!date) return "";
  const dateOptions = { year: "numeric", month: "numeric", day: "numeric" };
  const timeOptions = { hour: "numeric", minute: "numeric" };
  //TODO get locale string from user
  return (
    date.toLocaleDateString(undefined, dateOptions) +
    " - " +
    date.toLocaleTimeString(undefined, timeOptions)
  );
});
