import Vue from "vue";
import i18n from "./i18n";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./validate/index";
import "@/plugins/vueNotifications";
import axiosInterceptor from "@/plugins/axiosInterceptor";
import "@/plugins/vueGlobals";
import "@/filters";
import "vue-datetime/dist/vue-datetime.css";
import "@/plugins/vueCoolLightbox";
import "@/plugins/vueSentry";
import "@/styles/style.scss";
import App from "./App.vue";
import cmsService from "@/services/cmsService";

Vue.config.productionTip = false;

axiosInterceptor();

const meta = document.createElement("meta");
meta.name = "app_version";
meta.content = process.env.VUE_APP_VERSION;
document.getElementsByTagName("head")[0].appendChild(meta);

if (
  !process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES ||
  process.env.VUE_APP_EXCLUDE_EXTERNAL_SERVICES === "false"
) {
  cmsService.getTranslation().then((translations) => {
    if (translations) i18n.mergeLocaleMessage(i18n.locale, translations);
  });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
