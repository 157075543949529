

















import Vue from "vue";
import timezoneUtil from "@/utils/timezoneUtil";
import localeUtil from "@/utils/localeUtil";
import { Timezone } from "@/models/timezone";
import { LightBox } from "@/models/lightBox";
import styleUtil from "@/utils/styleUtil";
import PreferenceUtil from "@/utils/preferenceUtil";
import ConfirmDialog from "@/components/organisms/ConfirmDialog.vue";
import Snackbars from "@/components/organisms/Snackbars.vue";

export default Vue.extend({
  name: "App",
  components: {
    Snackbars,
    ConfirmDialog,
  },
  data() {
    return {
      key: 0,
    };
  },
  async beforeMount() {
    localeUtil.initLocale();
    timezoneUtil.initTimezone();
    styleUtil.initMode();
    this.$vuetify.theme.dark = this.$store.getters["session/darkMode"];
  },
  methods: {
    setMode(): void {
      this.$vuetify.theme.dark = this.darkMode;
      this.$store.commit("session/setDarkMode", this.darkMode);
    },
  },
  computed: {
    lightBox(): LightBox {
      return this.$store.getters["app/lightBox"];
    },
    timezone(): Timezone | null {
      return this.$store.getters["session/timezone"];
    },
    classes(): string {
      return this.lightBox.index !== null ? "lightbox-open" : "";
    },
    darkMode: {
      get(): boolean {
        return this.$store.getters["session/darkMode"];
      },
      set(darkMode: boolean): void {
        PreferenceUtil.setPreference("darkMode", darkMode);
        this.$vuetify.theme.dark = darkMode;
        this.$store.commit("session/setDarkMode", darkMode);
      },
    },
  },
  watch: {
    timezone() {
      this.key++;
    },
  },
});
