import Vue from "vue";
import i18n from "@/i18n";
import {
  DEFAULT_NOTIFICATION,
  NotificationType,
  Notification,
} from "@/models/notification";

export default {
  notify(data: Notification): void {
    Vue.notify({
      ...DEFAULT_NOTIFICATION,
      ...data,
    });
  },
  success(text: string, data?: Notification): void {
    this.notify({
      ...data,
      text: text,
      type: NotificationType.SUCCESS,
    } as Notification);
  },
  info(text: string, data?: Notification): void {
    this.notify({
      ...data,
      text: text,
      type: NotificationType.INFO,
    } as Notification);
  },
  warning(text: string, data?: Notification): void {
    this.notify({
      ...data,
      text: text,
      type: NotificationType.WARNING,
    } as Notification);
  },
  error(
    error: string | { response: { data: { errorCode: string } } },
    data?: Notification
  ): void {
    let text = error;
    if (typeof error === "object") {
      text = i18n.t("errorCode." + error?.response?.data.errorCode) as string;
    }
    this.notify({
      ...data,
      text: text,
      type: NotificationType.ERROR,
    } as Notification);
  },
};
