






import Vue from "vue";
import store from "@/store";
import VaSnackbar from "@/components/molecules/VaSnackbar.vue";

export default Vue.extend({
  components: {
    VaSnackbar,
  },
  computed: {
    message(): string {
      const notification = this.$store.getters["notifications/notification"];
      return notification ? notification.message : "";
    },
    type(): string | undefined {
      const notification = this.$store.getters["notifications/notification"];
      return notification ? notification.type : undefined;
    },
  },
  watch: {
    message(newVal: string) {
      this.displayNotification = newVal !== "";
    },
    displayNotification(newVal: boolean) {
      if (!newVal) {
        store.commit("notifications/dismissNotification");
      }
    },
  },
  data() {
    return {
      displayNotification: false,
    };
  },
});
