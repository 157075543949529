var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { key: _vm.key, class: _vm.classes, attrs: { id: "app" } },
    [
      _c("snackbars"),
      _c("confirm-dialog"),
      _c("CoolLightBox", {
        attrs: {
          id: "lightbox",
          items: _vm.lightBox.items,
          index: _vm.lightBox.index,
          useZoomBar: true,
          fullScreen: true
        },
        on: {
          close: function($event) {
            return _vm.$store.commit("app/closeLightBox")
          }
        }
      }),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }