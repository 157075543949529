export enum NotificationType {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warn",
  ERROR = "error",
}

export const DEFAULT_NOTIFICATION = {
  title: "",
  text: "",
  type: NotificationType.INFO,
  duration: 3000,
  speed: 300,
};

export interface Notification {
  title?: string;
  text: string;
  type: NotificationType;
  duration?: number;
  speed?: number;
}
