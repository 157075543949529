import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

//setInteractionMode("lazy");

import {
  email,
  required,
  min,
  max,
  min_value,
  max_value,
  regex,
  confirmed,
  length,
  digits,
  integer,
} from "vee-validate/dist/rules";
import i18n from "@/i18n";

extend("length", {
  ...length,
  message: i18n.t("validate.length") as string,
});

extend("email", {
  ...email,
  message: i18n.t("validate.email") as string,
});

extend("required", {
  ...required,
  message: i18n.t("validate.required") as string,
});

extend("digits", {
  ...digits,
  message: i18n.t("validate.digits") as string,
});

extend("min", {
  ...min,
  message: i18n.t("validate.min") as string,
});

extend("max", {
  ...max,
  message: i18n.t("validate.max") as string,
});

extend("max_value", {
  ...max_value,
  message: i18n.t("validate.maxValue") as string,
});

extend("min_value", {
  ...min_value,
  message: i18n.t("validate.minValue") as string,
});

extend("confirmed", {
  ...confirmed,
  message: i18n.t("validate.confirmed") as string,
});

extend("integer", {
  ...integer,
  message: i18n.t("validate.integer") as string,
});

extend("number", {
  ...regex,
  message: i18n.t("validate.number") as string,
  validate: (value: string) => (value ? value.match(/[0-9]/g) !== null : true),
});

extend("uppercase", {
  ...regex,
  message: i18n.t("validate.uppercase") as string,
  validate: (value: string) => (value ? value.match(/[A-Z]/g) !== null : true),
});

extend("special", {
  ...regex,
  message: i18n.t("validate.special") as string,
  validate: (value: string) => (value ? value.match(/\W|_/g) !== null : true),
});

const strongPasswordRegex =
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})";
extend("strong_password", {
  message: i18n.t("validate.strongPassword") as string,
  validate: (value: string) => {
    return new RegExp(strongPasswordRegex).test(value);
  },
});

const urlRegex =
  "https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)";
extend("url", {
  message: i18n.t("validate.url") as string,
  validate: (value: string) => {
    return new RegExp(urlRegex).test(value);
  },
});

const ampmRegex = "^(((0[1-9])|(1[0-2])):([0-5])([0-9])\\s(A|P)M)";
extend("ampm", {
  message: i18n.t("validate.ampm") as string,
  validate: (value: string) => {
    return new RegExp(ampmRegex).test(value);
  },
});
