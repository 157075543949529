import PreferenceUtil from "@/utils/preferenceUtil";
import store from "@/store";
import vuetify from "@/plugins/vuetify";

export default {
  getTheme(): any {
    const themes = vuetify.preset.theme.themes;
    return PreferenceUtil.getPreference("darkMode")
      ? themes.dark
      : themes.light;
  },
  initMode(): void {
    const mode = PreferenceUtil.getPreference("darkMode") || false;
    this.setDarkMode(mode);
  },
  setDarkMode(enabled: boolean): void {
    store.commit("session/setDarkMode", enabled);
  },
};
