var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        width: _vm.dialog.width ? _vm.dialog.width : "400",
        "content-class": "fullscreen"
      },
      model: {
        value: _vm.dialog.visible,
        callback: function($$v) {
          _vm.$set(_vm.dialog, "visible", $$v)
        },
        expression: "dialog.visible"
      }
    },
    [
      _c("va-card", [
        _vm.dialog.title
          ? _c("div", { staticClass: "text-3xl font-bold" }, [
              _vm._v(" " + _vm._s(_vm.dialog.title) + " ")
            ])
          : _vm._e(),
        _vm.dialog.description
          ? _c("div", { staticClass: "my-6" }, [
              _vm._v(_vm._s(_vm.dialog.description))
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "d-flex flex-row-reverse" },
          [
            (_vm.dialog.callback
            ? _vm.dialog.callback
            : null)
              ? _c("va-button", {
                  staticClass: "ml-3",
                  attrs: {
                    cta: _vm.dialog.ok
                      ? _vm.dialog.ok
                      : _vm.$t("common.confirm")
                  },
                  on: { click: _vm.confirm }
                })
              : _vm._e(),
            _c("va-button", {
              attrs: {
                type: "outline",
                color: "secondary",
                cta: _vm.dialog.ko ? _vm.dialog.ko : _vm.$t("common.dismiss")
              },
              on: { click: _vm.dismiss }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }