var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "va-snackbar",
    {
      attrs: { type: _vm.type },
      model: {
        value: _vm.displayNotification,
        callback: function($$v) {
          _vm.displayNotification = $$v
        },
        expression: "displayNotification"
      }
    },
    [_vm._v(_vm._s(_vm.message))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }